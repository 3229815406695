import {
	lightboxIndexAtom,
	lightboxMediaAtom,
	lightboxNavigationChange,
} from '@components/lightbox/atoms';
import { useTrackLightbox } from '@components/lightbox/hooks/use-track-lightbox';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import Media from '../../../../../pages/Storefront/components/Media';
import Styles from './overview.scss';

const small = [240, 215];
const tall = [240, 445];
const wide = [488, 215];

const imageDimensions = [small, tall, small, wide, tall, small, small, wide];

export const Overview = () => {
	const mediaItems = useAtomValue(lightboxMediaAtom);
	const setImage = useSetAtom(lightboxNavigationChange);
	const index = useAtomValue(lightboxIndexAtom);
	const trackLightbox = useTrackLightbox();

	const setActiveImage = (idx: number) => {
		setImage({
			value: idx,
			callback: () => trackLightbox('overview photo clicked', idx),
		});
	};

	return (
		<div className={classNames(Styles.overview, Styles.overviewGrid)}>
			{mediaItems.map((media, idx) => {
				const dimensions = imageDimensions[idx % 8];
				const key = `${idx}_overview_${media.id}`;
				const selected = idx === index;
				return (
					<div
						key={key}
						className={classNames(Styles.imgContainer, {
							[Styles.selected]: selected,
						})}
						onClick={() => setActiveImage(idx)}
						onKeyPress={() => setActiveImage(idx)}
						role="button"
						tabIndex={0}
					>
						<Media
							compact
							lazy
							smartCrop
							mediaItem={media}
							width={dimensions[0]}
							height={dimensions[1]}
							imageClass={Styles.img}
						/>
					</div>
				);
			})}
		</div>
	);
};
