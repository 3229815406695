import { MediaType } from '@typings/vendor/properties';
import { VendorMediaKey } from './atoms';

export const getFilterByMediaType = (
	mediaType: (MediaType | 'REVIEW') | undefined,
): VendorMediaKey => {
	switch (mediaType) {
		case 'PHOTO':
			return 'photos';
		case 'VIDEO':
			return 'videos';
		case '360TOUR':
			return 'tours';
		case 'REVIEW':
			return 'reviews';
		default:
			return 'all';
	}
};
