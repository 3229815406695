import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { Column } from '@xo-union/tk-component-grid';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { State } from 'types/redux';
import { InlineRfqSourceContent } from 'types/sourceContent';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';
import InlineRfqModal from '../../../../../InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../../../../cta_box/RfqButton';
import FavoritesButton from '../../../../../vendor_info/components/favorites_button';
import type { ModalSourceContent } from '../types';
import Styles from './styles.scss';

type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
	sourceContent: ModalSourceContent;
	closeModal?: () => void;
	closeOverviewModal?: () => void;
	context?: ReturnType<typeof useInlineRfqForm>;
}

export type FooterProps = StateProps & OwnProps;

const getInitiatorFromSourceContent = (sourceContent: ModalSourceContent) => {
	switch (sourceContent) {
		case 'media overview':
			return AnalyticsConstants.MEDIA_OVERVIEW;
		case 'lightbox':
			return AnalyticsConstants.PHOTO_GALLERY;
		case 'individual review gallery':
			return 'individual review gallery';
		default:
			return null;
	}
};

const Footer = ({
	membership,
	vendor,
	sourceContent,
	closeModal,
	closeOverviewModal,
	context,
}: FooterProps) => {
	const isLiteStorefront = useLiteStorefront();
	const formContext = useInlineRfqForm({
		initiator: getInitiatorFromSourceContent(
			sourceContent,
		) as InlineRfqSourceContent,
	});

	const handleCTAClick = () => {
		closeModal?.(); // close lightbox
		closeOverviewModal?.(); // close overview modal
		window.history.back(); // queues a task to navigate back
		setTimeout(() => {
			// schedule after navigation, otherwise rfq modal will be closed immediately after opening
			(context ?? formContext).openModal(); // open RFQ modal
		}, 0);
	};

	return (
		<>
			<InlineRfqModal context={formContext} />
			<div className={Styles.footer} role="presentation">
				<div className={classnames(Styles.buttonsContainer)}>
					<Column xs="6" className={Styles.buttonColumns}>
						<FavoritesButton
							expanded
							membership={membership}
							vendor={vendor}
							className={Styles.favButton}
							sourceContent={sourceContent}
						/>
					</Column>
					{!isLiteStorefront && (
						<Column xs="6" className={Styles.buttonColumns}>
							<RFQButton
								size="md"
								handleCTAClick={handleCTAClick}
								styleName={Styles.rfqButton}
							/>
						</Column>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: State) => ({
	membership: state.membership,
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(Footer);
