import { isMediaOfType, isPhotoWide } from '@utils/mediaUtils';
import { compose } from '@xo-union/react-css-modules';
import classnames from 'classnames';
import Styles from '../LightboxModal.scss';

const DEFAULT_PHOTO_STYLES = Styles.lightboxImage;
const DEFAULT_PILL_STYLES = Styles.lightboxPill;
const DEFAULT_MEDIA_WRAPPER_STYLES = Styles.lightboxMediaWrapper;

const getCarouselStyles = (isLandscape: boolean) => {
	if (!isLandscape) {
		return undefined;
	}

	return compose({
		relativeContainer: Styles.lightboxCarouselLandscape,
	});
};

const getPhotoStyles = (
	media: Vendor.PhotoMedia,
	isReviewsLightbox: boolean,
) => {
	if (isReviewsLightbox) {
		return {
			image: Styles.lightboxImageReviews,
			pill: classnames(DEFAULT_PILL_STYLES, Styles.lightboxPillTall),
		};
	}
	if (isPhotoWide(media)) {
		return {
			image: classnames(DEFAULT_PHOTO_STYLES, Styles.lightboxImageWide),
			pill: classnames(DEFAULT_PILL_STYLES),
		};
	}
	return {
		image: classnames(DEFAULT_PHOTO_STYLES, Styles.lightboxImageTall),
		pill: classnames(DEFAULT_PILL_STYLES, Styles.lightboxPillTall),
	};
};

const getImageAndPillStyles = (
	media: Vendor.Media,
	isReviewsLightbox = false,
) => {
	if (isMediaOfType<Vendor.PhotoMedia>(media, 'PHOTO')) {
		return getPhotoStyles(media, isReviewsLightbox);
	}
	return { image: '', pill: classnames(DEFAULT_PILL_STYLES) };
};

const getLightboxWrapperStyles = (
	media: Vendor.Media,
	isQuickResponder?: boolean,
) => {
	if (isMediaOfType<Vendor.PhotoMedia>(media, 'PHOTO') && !isPhotoWide(media)) {
		return classnames(
			DEFAULT_MEDIA_WRAPPER_STYLES,
			Styles.lightboxMediaWrapperTall,
		);
	}

	if (isMediaOfType<Vendor.TourMedia>(media, '360TOUR') && isQuickResponder) {
		return classnames(
			DEFAULT_MEDIA_WRAPPER_STYLES,
			Styles.lightboxMediaWrapperForTour,
		);
	}

	return classnames(DEFAULT_MEDIA_WRAPPER_STYLES);
};

export {
	isPhotoWide,
	getCarouselStyles,
	getPhotoStyles,
	getImageAndPillStyles,
	getLightboxWrapperStyles,
};
