import { lightboxShowOverview } from '@components/lightbox/atoms';
import { Carousel } from '@components/lightbox/components/body/components/carousel';
import { Overview } from '@components/lightbox/components/body/components/overview';
import { useAtomValue } from 'jotai';
import React from 'react';
import Styles from './styles.scss';

export const Body = () => {
	const showOverview = useAtomValue(lightboxShowOverview);

	return (
		<div className={Styles.body}>
			{showOverview && (
				<div className={Styles.overview}>
					<Overview />
				</div>
			)}
			<div className={Styles.carousel}>
				<Carousel />
			</div>
		</div>
	);
};
