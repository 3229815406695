import { IconButton } from '@xo-union/tk-component-icons';
import cx from 'classnames';
import React, { VFC } from 'react';
import Styles from '../../styles.scss';

interface Props {
	filled: boolean;
	onToggleHandler: () => void;
}

const FavoriteHeart: VFC<Props> = ({ filled, onToggleHandler }) => {
	return (
		<div className={Styles.heartContainer}>
			<IconButton
				size="md"
				className={cx(Styles.heartOutline, {
					[Styles.heartFilled]: filled,
				})}
				aria-label={'Save this vendor'}
				name={filled ? 'heart_filled' : 'heart'}
				onClick={onToggleHandler}
			/>
		</div>
	);
};

export default FavoriteHeart;
