import React, { FC } from 'react';
import Photo from './Photo';
import Tour from './Tour';
import Video from './Video';
import type { CommonImageProps } from './types';

export interface MediaProps extends CommonImageProps {
	canPlay?: boolean;
	compact?: boolean;
	fit?: boolean;
	height?: number;
	lazy?: boolean;
	mediaItem: Vendor.Media;
	preload?: boolean;
	smartCrop?: boolean;
	tabIndexOverride?: number;
	width?: number;
}

const Media: FC<MediaProps> = (props) => {
	const { canPlay, compact, imageClass, mediaItem, ...imageProps } = props;

	switch (mediaItem.mediaType) {
		case 'PHOTO':
			return (
				<Photo
					mediaItem={mediaItem as Vendor.PhotoMedia}
					imageClass={imageClass}
					{...imageProps}
				/>
			);
		case 'VIDEO':
			return (
				<Video
					mediaItem={mediaItem as Vendor.VideoMedia}
					canPlay={canPlay || false}
					{...imageProps}
				/>
			);
		case '360TOUR':
			return (
				<Tour
					mediaItem={mediaItem as Vendor.TourMedia}
					canPlay={canPlay || false}
					compact={compact || false}
					{...imageProps}
				/>
			);
		default:
			return null;
	}
};

export default Media;
