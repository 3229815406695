import Icon from '@xo-union/tk-component-icons';
import React from 'react';
import Styles from './styles.scss';
import { determineHeaderText } from './utils';

export interface HeaderProps {
	closeModal: () => void;
	isQuickLinkClick: boolean;
	mediaLength: number;
	vendorName: string;
	mediaType?: Vendor.Media['mediaType'];
}

const Header = ({
	vendorName,
	closeModal,
	isQuickLinkClick,
	mediaType,
	mediaLength,
}: HeaderProps) => {
	return (
		<div className={Styles.header}>
			<div className={Styles.titleContainer}>
				<h4 className={Styles.title}>
					{determineHeaderText({
						isQuickLinkClick,
						mediaLength,
						vendorName,
						mediaType,
					})}
				</h4>
			</div>
			<div className={Styles.closeBtnContainer}>
				<button
					aria-label="Close modal"
					name="close-btn-overview"
					className={Styles.closeBtn}
					onClick={closeModal}
					type="button"
				>
					<Icon name="close" size="md" />
				</button>
			</div>
		</div>
	);
};

export default Header;
