import Styles from './styles.scss';

export const is360Tour = (media: Vendor.Media[]) =>
	media.some((m) => m.mediaType === '360TOUR');
export const get360Tours = (
	media: Vendor.IndexedMedia[],
): Vendor.IndexedMedia[] => media.filter((m) => m.mediaType === '360TOUR');

export const getVideoClass = (canPlay: boolean, isPlaying: boolean): string => {
	const baseContainerClass = Styles.videoContainer;

	if (!canPlay) {
		return baseContainerClass;
	}

	if (isPlaying) {
		return `${baseContainerClass} ${Styles.isPlaying}`;
	}

	return `${baseContainerClass} ${Styles.canPlay}`;
};
