import formatSavedVendorData from '@utils/formatSavedVendorData';
import savedVendors from '@utils/savedVendors';
import type { SnackbarRef } from '@xo-union/tk-component-alerts';
import React, { useCallback } from 'react';
import { Track } from 'types/analytics';
import SavedMessage from '../components/saved-message';

export const useCreateFavorite = (
	alertRef: React.RefObject<SnackbarRef>,
	sessionToken: string | null,
	track: Track,
	vendor: Vendor.Decorated,
	fillFavoriteHeart: () => void,
	trackAddToFavorites: () => void,
	setSavedVendorId: (savedVendorId?: string | null) => void,
) => {
	return useCallback(
		(weddingId?: string) => {
			return savedVendors
				.create({
					sessionToken,
					weddingId,
					data: formatSavedVendorData(vendor),
				})
				.then((response) => {
					if (response.errors || !response.saved_vendor) {
						alertRef.current?.showAndDismiss({
							data: 'Adding To Your Vendors Failed. Please Retry',
							dismissTimeout: 5000,
						});
						return;
					}

					const savedMessage = <SavedMessage vendor={vendor} track={track} />;
					alertRef.current?.showAndDismiss({
						data: savedMessage,
						dismissTimeout: 5000,
					});
					fillFavoriteHeart();
					trackAddToFavorites();
					setSavedVendorId(response.saved_vendor.id);
				});
		},
		[
			vendor,
			alertRef,
			sessionToken,
			track,
			fillFavoriteHeart,
			trackAddToFavorites,
			setSavedVendorId,
		],
	);
};
