import { LightBoxType, openLightboxAtom } from '@components/lightbox/atoms';
import { useAppSelector } from '@redux/hooks';
import { useSetAtom } from 'jotai';
import { Media } from 'types/vendor';

interface OpenLightboxOptions {
	index?: number;
	mediaItems?: Media[];
	secondaryText?: string[];
	type?: LightBoxType;
	vendorId?: string;
	showOverview?: boolean;
}

export const useOpenLightbox = () => {
	const media = useAppSelector(
		(state) => state.vendor.vendor?.mediaSummary?.media || [],
	);
	const openLightbox = useSetAtom(openLightboxAtom);

	return (options: OpenLightboxOptions = {}) => {
		const { index, secondaryText, mediaItems, type, vendorId, showOverview } =
			options;

		openLightbox({
			index,
			secondaryText,
			media: mediaItems || media,
			type,
			vendorId,
			showOverview,
		});
	};
};
