import { setLightboxIsPlayingAtom } from '@components/lightbox/atoms';
import { useSetAtom } from 'jotai';

export const useLightboxIsPlaying = () => {
	const setLightboxIsPlaying = useSetAtom(setLightboxIsPlayingAtom);

	return (playingId: string) => {
		setLightboxIsPlaying(playingId);
	};
};
