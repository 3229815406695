import { OpenModalType } from '@xo-union/tk-component-membership-modal-redux';
import { useCallback } from 'react';

export const useOnToggleHandler = (
	membership: Redux.Membership,
	filled: boolean,
	weddingId: string | undefined,
	createFavorite: (weddingId?: string) => void,
	destroyFavorite: () => void,
	setOpenModal: React.Dispatch<React.SetStateAction<OpenModalType | null>>,
) => {
	return useCallback(() => {
		const isLoggedIn = !!membership.member;
		const toggleFavorite = () =>
			filled ? destroyFavorite() : createFavorite(weddingId);

		if (isLoggedIn) {
			toggleFavorite();
		} else {
			setOpenModal('SIGN_UP');
		}
	}, [
		membership.member,
		filled,
		weddingId,
		createFavorite,
		destroyFavorite,
		setOpenModal,
	]);
};
