import { tkHost } from '@settings';
import React, { VFC } from 'react';
import { Track } from 'types/analytics';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../../../decorators/vendorAnalytics';
import Styles from '../../styles.scss';

interface Props {
	vendor: Vendor.Decorated;
	track: Track;
}

const trackFavoritesListClick = (vendor: Vendor.Decorated, track: Track) => {
	const vendorAnalytics = new VendorAnalytics(vendor);
	track({
		event: AnalyticsConstants.CLICK_THROUGH_TO_VENDOR_LIST,
		properties: {
			sourcePage: AnalyticsConstants.STOREFRONT,
			sourceContent: AnalyticsConstants.FAVORITED_VENDOR,
			...vendorAnalytics.baseEventProps(),
		},
	});
};

const SavedMessage: VFC<Props> = ({ vendor, track }) => {
	const savedCategoryUrl: string = vendor.categorySlug;

	return (
		<span role="alert">
			Added to&nbsp;
			<a
				className={Styles.favLink}
				href={`${tkHost}/wedding-vendor-manager/saved/${savedCategoryUrl}`}
				onClick={() => trackFavoritesListClick(vendor, track)}
				rel="noopener noreferrer nofollow"
				target="_blank"
			>
				Your Vendors
			</a>
		</span>
	);
};

export default SavedMessage;
