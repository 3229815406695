import { lightboxIndexAtom } from '@components/lightbox/atoms';
import { Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { memo } from 'react';
import Styles from './photo-count.scss';

interface Props {
	totalCount: number;
	index: number;
}

export const PhotoCount = memo(({ index, totalCount }: Props) => {
	const activeIndex = useAtomValue(lightboxIndexAtom);
	return (
		<Body2
			className={classNames(Styles.photoCount, {
				[Styles.hidden]: activeIndex !== index,
			})}
		>{`${index + 1}/${totalCount}`}</Body2>
	);
});
