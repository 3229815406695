import React from 'react';

export const PlayIcon = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>Play Icon</title>
			<circle opacity="0.75" cx="16" cy="16" r="16" fill="white" />
			<path
				d="M22.2832 14.9177C22.9499 15.3026 22.9499 16.2648 22.2832 16.6497L13.2832 21.8459C12.6165 22.2308 11.7832 21.7496 11.7832 20.9798L11.7832 10.5875C11.7832 9.81774 12.6165 9.33661 13.2832 9.72151L22.2832 14.9177Z"
				fill="#262626"
			/>
		</svg>
	);
};
