import { Body2 } from '@xo-union/tk-ui-typography';
import React, { VFC } from 'react';

import Icon from '@xo-union/tk-component-icons';
import Styles from './styles.scss';

const TourCTA: VFC = () => (
	<div className={Styles.container}>
		<Icon name="virtual_tour" data-testid="360-tour" />
		<Body2 className={Styles.ctaText} bold>
			Virtual Tour
		</Body2>
	</div>
);

export default TourCTA;
