import { PillBadge } from '@xo-union/tk-component-badges';
import classNames from 'classnames';
import React, { FC } from 'react';
import Styles from './styles.scss';

export interface MobilePhotoCountProps {
	currentIndex: number;
	totalNumber: number;
	cssClassOverride?: string;
}

const MobilePhotoCount: FC<MobilePhotoCountProps> = (props) => {
	const { cssClassOverride, currentIndex, totalNumber } = props;

	return (
		<PillBadge className={classNames(Styles.pill, cssClassOverride)}>
			{currentIndex + 1}/{totalNumber}
		</PillBadge>
	);
};

export { MobilePhotoCount };
