import { mediaApiUrl } from '@settings';
import { Img, Picture } from '@xo-union/tk-component-picture';
import React, { useCallback, useMemo, VFC } from 'react';
import { PHOTO } from '../../../../../constants/media';
import SkeletonWrapper from '../../containers/Gallery/containers/SkeletonWrapper';
import PlaceholderImage from './PlaceholderImage';
import { PLACEHOLDER_CONSTANTS, QUALITY_PROP } from './constants';
import Styles from './styles.scss';
import type { CommonImageProps } from './types';

export interface ImageWrapperProps extends CommonImageProps {
	height?: number;
	width?: number;
	scaling?: number;
	imageStatus: CustomHooks.UseImageStatus;
	mediaItem: Vendor.Media;
	onError: (
		imageReference: React.SyntheticEvent<HTMLImageElement, Event>,
	) => void;
	onLoad: () => void;
	smartCrop?: boolean;
	placeholderClass?: string;
	placeholderContent?: JSX.Element;
	preload?: boolean;
	fit?: boolean;
	lazy?: boolean;
}

const ImageWrapper: VFC<ImageWrapperProps> = (props) => {
	const {
		placeholderClass,
		placeholderContent,
		height,
		width,
		scaling = 1.5,
		styleAttributes,
		imageClass,
		imageStatus,
		mediaItem,
		onError,
		onLoad,
		parentComponent,
		smartCrop,
		fit,
		lazy = true,
		preload = false,
	} = props;

	const determinedHeight = height && Math.round(height * scaling);
	const determinedWidth = width && Math.round(width * scaling);

	// don't try to render placeholder in gallery
	const status: CustomHooks.UseImageStatus =
		PLACEHOLDER_CONSTANTS.id === mediaItem.id ? 'ERRORED' : imageStatus;

	const isFitApplicable = useMemo(
		() => (width && height && fit) || false,
		[width, height, fit],
	);

	const imageSource = useMemo(() => {
		if (mediaItem.mediaType === PHOTO) {
			return mediaItem.url;
		}

		const nonPhotoMediaItem = mediaItem as Vendor.VideoMedia | Vendor.TourMedia;
		return `${mediaApiUrl}fetch/${encodeURIComponent(
			nonPhotoMediaItem.thumbnailUrl,
		)}/transform`;
	}, [mediaItem, isFitApplicable]);

	const imageComplete = useCallback(
		(node: HTMLImageElement | null) => {
			if (node?.complete) {
				onLoad();
			}
		},
		[onLoad],
	);

	return (
		<>
			<PlaceholderImage imageStatus={status} imageClass={placeholderClass}>
				{placeholderContent || null}
			</PlaceholderImage>
			<Picture
				quality={QUALITY_PROP}
				elementProps={{
					onError,
					onLoad,
				}}
				preload={preload}
			>
				<Img
					className={imageClass || ''}
					elementProps={{
						loading: lazy ? 'lazy' : 'eager',
						onError,
						onLoad,
						ref: imageComplete,
						style: {
							...(styleAttributes?.style || {}),
							display: status !== 'ERRORED' ? 'block' : 'none',
						},
					}}
					smartCrop={smartCrop || false}
					src={imageSource}
					fit={isFitApplicable}
					height={determinedHeight}
					width={determinedWidth}
				/>
			</Picture>
			<SkeletonWrapper
				className={Styles.skeletonWrapper}
				imageStatus={status}
				parentComponent={parentComponent}
			/>
		</>
	);
};

export default ImageWrapper;
