import { closeLightboxAtom } from '@components/lightbox/atoms';
import { useSetAtom } from 'jotai';

export const useCloseLightbox = () => {
	const closeLightbox = useSetAtom(closeLightboxAtom);

	return () => {
		closeLightbox();
	};
};
