import NavigableModal, {
	MODAL_TYPES,
} from '@components/NavigableModal/NavigableModal';
import {
	closeLightboxAtom,
	isReviewsLightboxAtom,
	lightboxIndexAtom,
	lightboxMediaAtom,
	lightboxOpenAtom,
} from '@components/lightbox/atoms';
import { Body, Header } from '@components/lightbox/components';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { ModalPortal } from '@xo-union/ui-accessibility';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import LightboxModal from '../../pages/Storefront/containers/StorefrontGallery/mobile_gallery/components/lightbox_modal/LightboxModal';
import { useTrackLightbox } from './hooks/use-track-lightbox';
import Styles from './styles.scss';

interface MobileLightboxProps {
	isOpen: boolean;
	onClose: () => void;
}

const MobileLightbox = ({ isOpen, onClose }: MobileLightboxProps) => {
	const media = useAtomValue(lightboxMediaAtom);
	const [index, setIndex] = useAtom(lightboxIndexAtom);
	const isReviewsLightbox = useAtomValue(isReviewsLightboxAtom);

	if (!isOpen || isReviewsLightbox) {
		return null;
	}

	return (
		<LightboxModal
			carouselMedia={media}
			mediaType="PHOTO"
			overviewIndex={index}
			setActiveImageIndex={setIndex}
			isModalOpen={isOpen}
			toggleModal={onClose}
		/>
	);
};

export const Lightbox = () => {
	const isOpen = useAtomValue(lightboxOpenAtom);
	const closeLightbox = useSetAtom(closeLightboxAtom);
	const trackLightbox = useTrackLightbox();
	const isMobile = useAppSelector((state) => selectIsMobile(state));

	const onClose = () => {
		trackLightbox('dismiss');
		closeLightbox();
	};

	if (isMobile) {
		return <MobileLightbox isOpen={isOpen} onClose={onClose} />;
	}

	return (
		<ModalPortal>
			<NavigableModal
				hash="lightbox-modal"
				isModalOpen={isOpen}
				closeCallback={onClose}
				type={MODAL_TYPES.CONTAINER}
				data-unified-lightbox={false}
			>
				<div className={Styles.container}>
					<Header />
					<Body />
				</div>
			</NavigableModal>
		</ModalPortal>
	);
};
