import Icon from '@xo-union/tk-component-icons';
import React, { FC } from 'react';
import Styles from './styles.scss';

export interface FullScreenLandscapeWrapperProps {
	closeModal: () => void;
}

const FullScreenLandscapeWrapper: FC<FullScreenLandscapeWrapperProps> = (
	props,
) => {
	const { children, closeModal } = props;
	return (
		<div className={Styles.container}>
			<div className={Styles.left} />
			{children}
			<div className={Styles.right}>
				<button
					aria-label="Close modal"
					name="close-btn-landscape-wrapper"
					onClick={closeModal}
					type="button"
					className={Styles.btn}
				>
					<Icon name="close" size="md" title="Close icon" />
				</button>
			</div>
		</div>
	);
};

export { FullScreenLandscapeWrapper };
