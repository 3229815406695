import { atom } from 'jotai';
import { Media } from 'types/vendor';

export type LightBoxType = 'reviews' | 'gallery' | 'realWeddings';

type State = {
	open: boolean;
	media: Media[];
	playing: boolean;
	index: number;
	playingId: string;
	secondaryText?: string[];
	type: LightBoxType;
	vendorId?: string;
	showOverview?: boolean;
};

export const initialState: State = {
	open: false,
	media: [],
	playing: false,
	index: 0,
	playingId: '',
	secondaryText: [],
	type: 'gallery',
	vendorId: undefined,
	showOverview: true,
};

type OpenLightbox = {
	index?: number;
	media: Media[];
	secondaryText?: string[];
	type?: LightBoxType;
	vendorId?: string;
	showOverview?: boolean;
};

export const lightboxOpenAtom = atom(initialState.open);
export const lightboxMediaAtom = atom<Media[]>([]);
export const lightboxPlayingAtom = atom(initialState.playing);
export const lightboxIndexAtom = atom(initialState.index);
export const lightboxPlayingIdAtom = atom(initialState.playingId);
export const lightboxSecondaryTextAtom = atom(initialState.secondaryText);
export const lightboxTypeAtom = atom(initialState.type);
export const lightboxVendorIdAtom = atom(initialState.vendorId);
export const lightboxShowOverview = atom(initialState.showOverview);

export const openLightboxAtom = atom<null, [value: OpenLightbox], void>(
	null,
	(_get, set, value) => {
		set(lightboxMediaAtom, value.media);
		set(
			lightboxIndexAtom,
			value.index && value.index > -1 && value.index < value.media?.length
				? value.index
				: initialState.index,
		);
		set(
			lightboxSecondaryTextAtom,
			value.secondaryText || initialState.secondaryText,
		);
		set(lightboxOpenAtom, true);
		set(lightboxTypeAtom, value.type || initialState.type);
		set(lightboxVendorIdAtom, value.vendorId || initialState.vendorId);
		set(lightboxShowOverview, value.showOverview ?? initialState.showOverview);
	},
);

export const closeLightboxAtom = atom<null, [], void>(null, (_get, set) => {
	set(lightboxIndexAtom, initialState.index);
	set(lightboxOpenAtom, initialState.open);
	set(lightboxMediaAtom, initialState.media);
	set(lightboxPlayingAtom, initialState.playing);
	set(lightboxPlayingIdAtom, initialState.playingId);
	set(lightboxSecondaryTextAtom, initialState.secondaryText);
	set(lightboxTypeAtom, initialState.type);
	set(lightboxVendorIdAtom, initialState.vendorId);
});

export const setLightboxIsPlayingAtom = atom<null, [value: string], void>(
	null,
	(_get, set, value) => {
		set(lightboxPlayingAtom, value !== '');
		set(lightboxPlayingIdAtom, value);
	},
);

export const getLightboxStatusAtom = atom((get) => ({
	initialIndex: get(lightboxIndexAtom),
	isOpen: get(lightboxOpenAtom),
	isPlaying: get(lightboxPlayingAtom),
	playingId: get(lightboxPlayingIdAtom),
}));

export type LightboxOrientation = 'portrait' | 'landscape' | 'unknown';

export const lightboxLandscapeAtom = atom((get) => {
	const index = get(lightboxIndexAtom);
	const mediaItems = get(lightboxMediaAtom);
	const media: Media | null = mediaItems.length ? mediaItems[index] : null;

	if (!media || media.mediaType !== 'PHOTO') {
		return 'landscape';
	}

	const height = 'height' in media ? Number(media.height) : 0;
	const width = 'width' in media ? Number(media.width) : 0;

	if (height === 0 || width === 0) {
		return 'unknown';
	}
	return width >= height ? 'landscape' : 'portrait';
});

export const lightboxNextImageAtom = atom<
	null,
	[callback: (() => void) | undefined],
	void
>(null, (get, set, callback) => {
	const index = get(lightboxIndexAtom);
	const mediaItems = get(lightboxMediaAtom);

	if (index < mediaItems.length - 1) {
		set(lightboxNavigationChange, {
			value: index + 1,
			callback,
		});
	}
});

export const lightboxPrevImageAtom = atom<
	null,
	[callback: (() => void) | undefined],
	void
>(null, (get, set, callback) => {
	const index = get(lightboxIndexAtom);

	if (index > 0) {
		set(lightboxNavigationChange, {
			value: index - 1,
			callback,
		});
	}
});

interface NavigationChange {
	value: number;
	callback?: (index: number) => void;
}

export const lightboxNavigationChange = atom<null, [NavigationChange], void>(
	null,
	(get, set, { value, callback }) => {
		const index = get(lightboxIndexAtom);
		if (index !== value) {
			set(lightboxIndexAtom, value);
			if (callback) callback(value);
			set(setLightboxIsPlayingAtom, '');
		}
	},
);

export const isReviewsLightboxAtom = atom(
	(get) => get(lightboxTypeAtom) === 'reviews',
);

export const isRealWeddingsLightboxAtom = atom(
	(get) => get(lightboxTypeAtom) === 'realWeddings',
);
