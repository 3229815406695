import { isReviewsLightboxAtom } from '@components/lightbox/atoms';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../../../decorators/vendorAnalytics';

export type LightboxActionType =
	| 'swipe right'
	| 'swipe left'
	| 'play'
	| 'play tour'
	| 'dismiss';

const useTrackLightboxInteraction = (vendor: Vendor.Decorated) => {
	const { track } = useAnalyticsContext();
	const isReviewsLightbox = useAtomValue(isReviewsLightboxAtom);
	const [event, sourceContent] = useMemo(() => {
		if (isReviewsLightbox)
			return ['Review Interaction', 'individual review gallery'];
		return [
			AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
			AnalyticsConstants.PHOTO_GALLERY,
		];
	}, [isReviewsLightbox]);
	return useCallback(
		(action: LightboxActionType) => {
			const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
			const vendorAnalytics = new VendorAnalytics(vendor);
			track({
				event,
				properties: {
					action,
					sourcePage: `${paidStatus} storefront`,
					sourceContent,
					...vendorAnalytics.baseEventProps(),
				},
			});
		},
		[track, vendor],
	);
};

export default useTrackLightboxInteraction;
