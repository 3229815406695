export interface DetermineHeaderTextParams {
	isQuickLinkClick: boolean;
	mediaLength: number;
	vendorName: string;
	mediaType?: Vendor.Media['mediaType'];
}

const determineHeaderText = ({
	isQuickLinkClick,
	mediaLength,
	mediaType,
	vendorName,
}: DetermineHeaderTextParams) => {
	if (isQuickLinkClick && mediaType === '360TOUR') {
		return `${vendorName} Virtual Tours (${mediaLength})`;
	}

	if (isQuickLinkClick && mediaType === 'VIDEO') {
		return `${vendorName} Videos (${mediaLength})`;
	}

	return `${vendorName}`;
};

export { determineHeaderText };
